import Footer from '@/components/Footer/Footer'
import { getPageApi } from '@/utils/api'
import { GetServerSidePropsContext } from 'next'
import PageSection from '../src/components/PageSection'
import SEOComponent from '@/components/Misc/SEO'
import { useEffect } from 'react'
import { MARKETING_PAGE_VIEW_ANALYTICS_EVENT_NAME, useAmplitude } from '@/utils/hooks/useAmplitude'
import { GetPageQuery } from 'graphql/gql/wrapper/graphql'
import Header from '@/components/Header/HeaderCommon'
import { CmsPageSlugObjType, getAllPagesSlugs } from '@/utils/cmsUtils'
import { windowExists } from '@/utils/windowUtils'

export type SinglePageType = GetPageQuery['allMkMarketingPage'][0]
export type MkSEO = SinglePageType['mk_seo']

type SlugProps = {
  page: SinglePageType
  cmsSlugObjs: CmsPageSlugObjType[]
}

const Slug = ({ page, cmsSlugObjs }: SlugProps) => {
  const { mk_slug, mk_pageBuilder, mk_header, mk_footer, mk_pageNameAnalyticsEventProperty, mk_seo } = page
  const cmsSlugSet = new Set(cmsSlugObjs.map((slug) => slug?.mk_slug?.current))

  const { logAmplitudeEvent, setPage, setCmsSlugSet } = useAmplitude()

  useEffect(() => {
    // Send page view event to Amplitude
    // Will send event twice on localhost because of reactStrictMode config in next.config.js
    logAmplitudeEvent(
      MARKETING_PAGE_VIEW_ANALYTICS_EVENT_NAME,
      null,
      { 'Page Name': mk_pageNameAnalyticsEventProperty ?? (mk_slug?.current as string) },
      { isPageViewEvent: true },
    )

    // Set state for use in interaction events
    setPage(mk_pageNameAnalyticsEventProperty ?? (mk_slug?.current as string))
    setCmsSlugSet(cmsSlugSet)
  }, [windowExists() && window.location.href])

  const pageSections = mk_pageBuilder?.map((pageItem, index) => (
    <PageSection details={pageItem as any} key={pageItem?.__typename + index.toString()} />
  ))
  return (
    <>
      {mk_seo && <SEOComponent seoData={{ mk_seo, slug: mk_slug?.current ?? '' }} />}
      <Header headerToDisplay={mk_header ?? 'None'} />

      {pageSections}
      {mk_footer && <Footer />}
    </>
  )
}

export const getServerSideProps = async ({ params, preview: isPreview, res }: GetServerSidePropsContext) => {
  try {
    const slugArray = (params as { slug: Array<string> }).slug
    const slugPath = slugArray.join('/').toLowerCase()

    // If slug is uppercase in Sanity (unlikely due to validation there), page won't be found by getPageApi()
    let pageQueryResponse = await getPageApi(slugPath, !!isPreview)

    let page = pageQueryResponse.allMkMarketingPage[0]

    // If preview page no longer exists, get the actual page
    if (isPreview && !page) {
      pageQueryResponse = await getPageApi(slugPath, false)
      page = pageQueryResponse.allMkMarketingPage[0]
    }

    if (pageQueryResponse.allMkMarketingPage.length <= 0) {
      console.error(`\nSlug: ${slugPath}  not in Sanity. Data: ${JSON.stringify(pageQueryResponse)}`)

      res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=10')
      return {
        notFound: true,
      }
    }

    let cmsSlugObjs = [] as CmsPageSlugObjType[]

    try {
      cmsSlugObjs = (await getAllPagesSlugs()) ?? []
    } catch (err) {
      //  Fallback: Analytics will flush before all navigation. May cause slight delay after clicking links before nav
      console.warn(`\nError in getAllPagesSlugs(). Navigation between pages may be slowed.\n`, err)
    }

    res.setHeader('Cache-Control', 'public, s-maxage=60, stale-while-revalidate=30')
    return { props: { page, cmsSlugObjs } }
  } catch (err) {
    // Fallback: Will show 404 until error is resolved
    console.error(`\nError in File: [...slug].tsx | Slug: ${params?.slug}\n`, err)

    res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=10')
    return {
      notFound: true,
    }
  }
}

export default Slug
